import React, { Component } from 'react';

import shop_local from '../images/shop_local.jpg';

class BusinessOwners  extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">
                    {/*Title*/}
                    <div class="row align-items-center">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <h1 id="white_background_text">Business Owners</h1>
                            <hr id="standard_line"></hr>
                            <p class="insurance_type_text" id="homepage_icon_text">
                                Managing your small business can be complex, selecting the right Business insurance for your 
                                unique operation doesn't have to be. No matter the type of business, The Heberling Agency can 
                                help you customize an insurance package to reduce your risk and protect your investment.
                            </p>
                            
                        </div>
                        <div class="col-md-3"></div>
                    </div>

                    <hr></hr>
                    

                    {/*Images*/}
                    <div id="insurance_images" class="row align-items-center">
                        
                        <div class="col-md-12">
                            <img src={shop_local} class="img-thumbnail" width="500" height="333" alt="Harley Davidson"></img>
                        </div>
                      
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default BusinessOwners;