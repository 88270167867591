import React, { Component } from 'react';

import atv from '../images/atv.jpg';

class RecreationalVehicles  extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">
                    {/*Title*/}
                    <div class="row align-items-center">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <h1 id="white_background_text">Recreational Vehicles</h1>
                            <hr id="standard_line"></hr>
                            <p class="insurance_type_text" id="homepage_icon_text">
                                When enjoying the great outdoors, you shouldn't have to be thinking about your insurance coverage. 
                                Make sure you have Recreational insurance coverage. Whether you own a boat, travel trailer, ATV, or 
                                motor home, The Heberling Agency can find you flexible coverage options and discounts.
                                <br></br><br></br>
                                Maybe you spend summers waterskiing at the lake. You may enjoy exploring the backcountry on ATVs or 
                                snowmobiles. Or you might consider an RV the perfect way to travel — experiencing new places but still 
                                getting to spend every night in your own bed.
                                <br></br><br></br>
                                We understands your active lifestyle — and your desire to help protect the vehicles that go with it. 
                                Our agents can help with knowledgeable insights as you choose the coverage you want for these vehicles.
                            </p>
                            
                        </div>
                        <div class="col-md-3"></div>
                    </div>

                    <hr></hr>
                    

                    {/*Images*/}
                    <div id="insurance_images" class="row align-items-center">
                        
                        <div class="col-md-12">
                            <img src={atv} class="img-thumbnail" width="500" height="334" alt="Harley Davidson"></img>
                        </div>
                      
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default RecreationalVehicles;