import React, { Component } from 'react';

import family from '../images/family.jpg';

class LifeInsurance  extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">
                    {/*Title*/}
                    <h1 id="white_background_text">Life Insurance</h1>
                    <hr id="standard_line"></hr>
                    <br></br>
                    <div class="row align-items-center">
                        <div class="col-md-1"></div>
                        <div class="col-md-5">
                            
                            <p class="insurance_type_text" id="homepage_icon_text">
                                What do you love about your life? You might be grateful for a partner who understands you better 
                                than anyone else. Maybe you delight in watching your children embrace their talents. Perhaps a 
                                recent promotion is giving you the chance to flex some leadership skills.
                                <br></br>
                                If all goes according to plan, you’ll pay off the mortgage, put your kids through college and 
                                enjoy a long retirement. But you know the unexpected could happen — do you want to help plan for 
                                your family’s future if they must go on without you? Life insurance may help lighten their financial 
                                burden.
                                <br></br>
                                Your Heberling Agency agent can show you a number of coverage options you can tailor to your 
                                priorities and budget. In fact, life insurance plans may be more affordable than you might think
                            </p>
                            
                        </div>
                        <div class="col-md-5">
                            <img src={family} class="img-thumbnail" width="267" height="400" alt="Family"></img>
                        </div>
                        <div class="col-md-1"></div>
                    </div>

                    
                    <br></br>
                 
                    <hr id="insurance_type_line"></hr>
                    {/*Categories*/}
                    <div class="row align-items-center">
                        <div class="col-md-2">
                            
                        </div>
                        <div class="col-md-8">
                            {/*Whole Life Insurance*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#whole_life" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Whole Life Insurance</h3>

                                    <div id="whole_life" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                        What do you want from your life insurance? You might be looking for predictability — a 
                                        policy you keep permanently, premiums that never change and a guaranteed death benefit. 
                                        Maybe you like the idea of a policy that builds cash value you can access to help you 
                                        with financial obligations. Perhaps you want to help protect your family’s financial 
                                        future with higher coverage levels.
                                        <br></br><br></br>
                                        Whole life is permanent insurance that might be a good option. As long as you continue 
                                        paying your premium, it may be a way to help prepare for the future and provide for heirs.
                                        <br></br><br></br>
                                        Talk to your agent about ways whole life insurance may help you reach your goals.
                                        </p>
                                    </div>
                                </div>
                            </a>

                            {/*Term Life Insurance*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#term_life" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Term Life Insurance</h3>

                                    <div id="term_life" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                        You have a growing family — and the financial obligations that come with it. Let’s say 
                                        you have a house with a 30-year mortgage, a 48-month auto loan and a college fund you’ll 
                                        start using in 10 years. In a circumstance like this, a term policy might be an economical 
                                        way to carry a large amount of life insurance.
                                        <br></br><br></br>
                                        Term life insurance offers level premiums for a specific period of time — generally 10, 20, 
                                        or 30 years. The initial level premium tends to be lower than comparable permanent coverage. 
                                        Proceeds may help your family financially if the unexpected happens when they still have major 
                                        expenses.
                                        <br></br><br></br>
                                        Generally, the shorter the term, the less expensive term coverage can be. While premiums 
                                        remain level for the term period, they will rise if you decide to continue the coverage beyond 
                                        the level premium period
                                        </p>
                                    </div>
                                </div>
                            </a>
                 
                            
                        </div>
                        <div class="col-md-2">
                            
                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default LifeInsurance;