import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Header extends Component {
  render() {
    return (
        <header>
            <nav class="navbar navbar-expand-sm bg-dark navbar-dark">
                <Link id="navbar_text" class="navbar-brand" to="/">HEBERLING AGENCY</Link>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                                Insurance Types
                            </a>
                            <div id="navbar_dropdown" class="dropdown-menu">
                                <Link id="navbar_dropdown_item" class="dropdown-item" to="/HomeInsurance">Home Insurance</Link>
                                <Link id="navbar_dropdown_item" class="dropdown-item" to="/AutoInsurance">Auto Insurance</Link>
                                <Link id="navbar_dropdown_item" class="dropdown-item" to="/MotorcycleInsurance">Motorcycle Insurance</Link>
                                <Link id="navbar_dropdown_item" class="dropdown-item" to="/LifeInsurance">Life Insurance</Link>
                                <Link id="navbar_dropdown_item" class="dropdown-item" to="/BusinessOwners">Business Owners</Link>
                                <Link id="navbar_dropdown_item" class="dropdown-item" to="/GeneralLiability">General Liability</Link>
                                <Link id="navbar_dropdown_item" class="dropdown-item" to="/UmbrellaPolicies">Umbrella Policies</Link>
                                <Link id="navbar_dropdown_item" class="dropdown-item" to="/PersonalArticles">Personal Articles</Link>
                                <Link id="navbar_dropdown_item" class="dropdown-item" to="/RecreationalVehicles">Recreational Vehicles</Link>
                            </div>
                        </li>
                        <li class="nav-item">
                            <Link id="navbar_text" class="nav-link" to="/AboutUs">About Us</Link>
                        </li>
                        <li class="nav-item">
                            <Link id="navbar_text" class="nav-link" to="/Team">Meet The Team</Link>
                        </li>
                        <li class="nav-item">
                            <Link id="navbar_text" class="nav-link" to="/Contact">Contact</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
  }
}

export default Header;