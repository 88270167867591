import React, { Component } from 'react';

import steve from '../images/steve.jpg'
import gale from '../images/gale.png';
import emily from '../images/emily.jpg'

import steve2 from '../images/steve2.jpg'
import gale2 from '../images/gayle2.jpg'
import emily_profile from '../images/emily_profile.jpeg'

class Team  extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">
                    <div class="row align-items-center">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <h1 id="white_background_text">Meet The Team</h1>
                            <hr id="standard_line"></hr>
                        </div>
                        <div class="col-md-4"></div>
                    </div>

                    <div class="row justify-content-center">
                            {/*Steve Hauser*/}
                            <div id="card_col" class="col-lg-3">        
                                <div class="card">
                                    <img src={steve2} alt="Steve Hauser" width="100%" ></img>
                                    <div class="container">
                                        <h2>Steven Hauser</h2>
                                        <p id="card_title">Owner and President</p>

                                        <hr></hr>
                                        {/*<a id="team_link" target="_blank" href="" data-toggle="modal" data-target="#steveModal">
                                            <i id="team_icon" class="fa fa-info-circle fa-2x fa-fw"></i>
                                        </a>
                                        <i id="team_icon" class="fa fa-facebook fa-2x fa-fw"></i>
                                        <i id="team_icon" class="fa fa-instagram fa-2x fa-fw"></i>*/}
                                        <p class="justify_text" id="homepage_icon_text">
                                            <b>Steven</b> has served as the President of Heberling Agency, Inc. since 2008. 
                                            A graduate of East Stroudsburg University, he has worked as a licensed agent 
                                            and broker at Heberling Agency for 22 years. Steven is a lifelong resident 
                                            of Stroudsburg and is also a local musician. You can find him singing and 
                                            playing guitar around Stroudsburg. He enjoys the outdoors and loves paddle 
                                            boarding with his two dogs, Riley and Quincy. Steven is very fond of classic 
                                            cars- look for him riding around town in his ’63 Cadillac with Riley and 
                                            Quincy sticking their heads out the window! Stop in to chat with Steven!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/*Gayle Stumpp*/}
                            {/*}
                            <div id="card_col" class="col-lg-3">
                                <div class="card">
                                    <img src={gale2} alt="Gayle Stumpp" width="100%"></img>
                                    <div class="container">
                                        <h2>Gayle Stumpp</h2>
                                        <p id="card_title">Senior Agent</p>

                                        <hr></hr>
                                        <p class="justify_text" id="homepage_icon_text">
                                            <b>Gayle</b> has been with The Heberling Agency, Inc. since 2002 and she has been our senior licensed
                                            insurance agent since 2011. Gayle currently resides in Stroudsburg with her husband and is the proud
                                            mother of two daughters. She is a devoted mother and wife and spends most of her free time with her
                                            family. In her free time she enjoys county line dancing, long walks and keeping her home tidy. She
                                            adores pug dogs and volunteers for local animal shelters when she can. Come say hello to Gayle!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            */}
                            {/*Emily Hauser*/}
                            <div id="card_col" class="col-lg-3">
                                <div class="card">
                                    <img src={emily_profile} alt="Emily Hauser" width="100%"></img>
                                    <div class="container">
                                        <h2>Emily Hauser</h2>
                                        <p id="card_title">Producer</p>
                                        
                                        <hr></hr>
                                        {/*<a id="team_link" target="_blank" href="" data-toggle="modal" data-target="#emilyModal">
                                            <i id="team_icon" class="fa fa-info-circle fa-2x fa-fw"></i>
                                        </a>
                                        <i id="team_icon" class="fa fa-facebook fa-2x fa-fw"></i>
                                        <i id="team_icon" class="fa fa-instagram fa-2x fa-fw"></i>*/}
                                        <p class="justify_text" id="homepage_icon_text">
                                            <b>Emily</b> is a new addition to The Heberling Agency team. She is a licensed insurance agent, currently
                                            residing in Stroudsburg. Emily has 5+ years of business management and sales experience and is
                                            currently pursuing a bachelor’s degree in Business Administration. She is an assistant track and field
                                            coach for Northampton County Special Olympics and is the Chairwoman of the A Pathway to Santa
                                            Organization that benefits local cancer patients. In her spare time you can find her curled up with her
                                            fluffy maine coon cats, Blue and Floyd, getting lost in a good book or watching HGTV. She enjoys playing
                                            a good tennis match and letting her shepherd mix Ellie play ball girl. She recently renovated her home
                                            and thoroughly enjoys reorganizing and redecorating. She is excited to bring some revitalization to The
                                            Heberling Agency. Stop in to meet Emily someday soon!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

                
                {/*Steve Hauser Modal
                <div class="modal fade" id="steveModal">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
    
                            
                            <div class="modal-header">
                                <h4 class="modal-title">Steve Hauser</h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            
                            
                            <div class="modal-body">
                                <p class="justify_text" id="homepage_icon_text">
                                    <b>Steven</b> has served as the President of Heberling Agency, Inc. since 2008. 
                                    A graduate of East Stroudsburg University, he has worked as a licensed agent 
                                    and broker at Heberling Agency for 22 years. Steven is a lifelong resident 
                                    of Stroudsburg and is also a local musician. You can find him singing and 
                                    playing guitar around Stroudsburg. He enjoys the outdoors and loves paddle 
                                    boarding with his two dogs, Riley and Quincy. Steven is very fond of classic 
                                    cars- look for him riding around town in his ’63 Cadillac with Riley and 
                                    Quincy sticking their heads out the window! Stop in to chat with Steven!
                                </p>
                            </div>
                            
                            
                            <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                            
                        </div>
                        </div>
                </div>
                
                
                <div class="modal fade" id="gayleModal">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
    
                            
                            <div class="modal-header">
                                <h4 class="modal-title">Gayle Stumpp</h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            
                            
                            <div class="modal-body">
                                <p class="justify_text" id="homepage_icon_text">
                                    <b>Gayle</b> has been with The Heberling Agency, Inc. since 2002 and she has been our senior licensed
                                    insurance agent since 2011. Gayle currently resides in Stroudsburg with her husband and is the proud
                                    mother of two daughters. She is a devoted mother and wife and spends most of her free time with her
                                    family. In her free time she enjoys county line dancing, long walks and keeping her home tidy. She
                                    adores pug dogs and volunteers for local animal shelters when she can. Come say hello to Gayle!
                                </p>
                            </div>
                            
                            
                            <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                            
                        </div>
                        </div>
                </div>
                
                
                <div class="modal fade" id="emilyModal">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
    
                            
                            <div class="modal-header">
                                <h4 class="modal-title">Emily Hauser</h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            
                            
                            <div class="modal-body">
                                <p class="justify_text" id="homepage_icon_text">
                                    <b>Emily</b> is a new addition to The Heberling Agency team. She is a licensed insurance agent, currently
                                    residing in Stroudsburg. Emily has 5+ years of business management and sales experience and is
                                    currently pursuing a bachelor’s degree in Business Administration. She is an assistant track and field
                                    coach for Northampton County Special Olympics and is the Chairwoman of the A Pathway to Santa
                                    Organization that benefits local cancer patients. In her spare time you can find her curled up with her
                                    fluffy maine coon cats, Blue and Floyd, getting lost in a good book or watching HGTV. She enjoys playing
                                    a good tennis match and letting her shepherd mix Ellie play ball girl. She recently renovated her home
                                    and thoroughly enjoys reorganizing and redecorating. She is excited to bring some revitalization to The
                                    Heberling Agency. Stop in to meet Emily someday soon!
                                </p>
                            </div>
                            
                            
                            <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                            
                        </div>
                        </div>
                </div>
                */}








            </div>
        );
    }
}
export default Team;