import React, { Component } from 'react';

import office from '../images/office3.jpg';
import office2 from '../images/office2.jpg';
import old_office from '../images/old_office.jpg';
import logo from '../images/logoborderless.jpg';
import bni from '../images/bni.jpg';

class AboutUs  extends Component {
    componentDidMount() {
        var aScript = document.createElement('script');
        aScript.type = 'text/javascript';
        aScript.src = "https://greaterpoconochamber.chambermaster.com/Content/Script/Member.js";

        document.head.appendChild(aScript);

        aScript.onload = function() {
            new window.MNI.Widgets.Member("mni-membership-637120971595058361",{member:877,styleTemplate:"#@id{text-align:center;position:relative}#@id .mn-widget-member-name{font-weight:700}#@id .mn-widget-member-logo{max-width:100%}"}).create();
        };
    
      }

    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">

                    {/*Title*/}
                    <div class="row align-items-center">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <h1 id="white_background_text">About Us</h1>
                            <hr id="standard_line"></hr>
                        </div>
                        <div class="col-md-4"></div>
                    </div>

                    {/*Logo*/}
                    <div class="row align-items-center">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            <img src={logo}  width="716" height="253" alt="Heberling Agency Logo"></img>
                            <br></br><br></br>
                            <hr></hr>
                        </div>
                        <div class="col-md-1"></div>
                    </div>

                    {/*Section 1*/}
                    <div class="row align-items-center about_sections">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <p class="justify_text" id="homepage_icon_text">
                                The Heberling Agency Inc. is your local, family owned, insurance agency founded 
                                right here in Stroudsburg in 1933. We are proud to say that we’ve been serving this 
                                community ever since, located conveniently in town on Phillips Street. 
                            </p>
                        </div>
                        <div class="col-md-6">
                            <img src={office} class="img-thumbnail" width="242" height="320" alt="Office"></img>
                        </div>
                    </div>

                    {/*Divider*/}
                    <div class="row align-items-center">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            
                            <hr></hr>
                        </div>
                        <div class="col-md-1"></div>
                    </div>

                    {/*Section 2*/}
                    <div class="row align-items-center about_sections">
                        <div class="col-md-6">
                            <img src={old_office} class="img-thumbnail" width="320" height="215" alt="Office"></img>
                        </div>
                        <div class="col-md-4">
                            <p class="justify_text" id="homepage_icon_text">
                                We offer a diverse set of insurance services through an experienced group of agents 
                                and brokers. We provide competitive rates on Home, Auto, Life, Commercial, General Liability, 
                                Motorcycle, Rental Properties, Personal Article Policies, Umbrella Policies, 
                                Recreational Vehicles such as boats, snowmobiles and RV’s and more. We provide 
                                coverage through an array of companies including Progressive, Travelers, Hartford, 
                                Safeco, National General, Capitol and surplus lines.
                            </p>
                        </div>
                        <div class="col-md-2"></div>
                    </div>

                    {/*Divider*/}
                    <div class="row align-items-center">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            
                            <hr></hr>
                        </div>
                        <div class="col-md-1"></div>
                    </div>

                    {/*Section 2*/}
                    <div class="row align-items-center about_sections">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <p class="justify_text" id="homepage_icon_text">
                                Give us a call or stop on into our office for a cup of coffee and a personalized chat 
                                with one of our knowledgeable agents for an always-free quote. We look forward to 
                                customizing your insurance package to meet your specific needs and safeguard you from 
                                specific risks presented to you, your family, or your business. We pride ourselves on 
                                providing a superior level of personalized service that simply isn't possible to get from 
                                a computer. We can't wait to help you save money and give you the confidence knowing that 
                                we’ve got you covered.
                            </p>
                        </div>
                        <div class="col-md-6">
                            <img src={office2} class="img-thumbnail" width="320" height="237" alt="Office"></img>
                        </div>
                    </div>

                    {/*Divider*/}
                    <div class="row align-items-center">
                        <div class="col-md-1"></div>
                        <div class="col-md-10">
                            
                            <hr></hr>
                        </div>
                        <div class="col-md-1"></div>
                    </div>

                    {/*Chamber of Commerce*/}
                    <div class="row align-items-center about_sections">
                        <div class="col-md-6">
                            <div id="mni-membership-637120971595058361"></div>
                            
                        </div>
                        <div class="col-md-6">
                        <img src={bni} alt="BNI"></img>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutUs;