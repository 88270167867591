import React, { Component } from 'react';

import construction from '../images/construction.jpg';
import aerial from '../images/aerial.jpg';
import fence from '../images/fence.jpg';

class HomeInsurance  extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">
                    {/*Title*/}
                    <div class="row align-items-center">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <h1 id="white_background_text">Home Insurance</h1>
                            <hr id="standard_line"></hr>
                            <p class="insurance_type_text" id="homepage_icon_text">
                                Protect your home the way it protects you. Your home is both a valuable asset and the center of your life, so it’s extra-important to cover every risk with homeowners insurance. Get the right policy and you’ll take care of the small and large risks related to owning a house.
                            </p>
                            
                        </div>
                        <div class="col-md-3"></div>
                    </div>

                    <hr></hr>
                    

                    {/*Images*/}
                    <div id="insurance_images" class="row align-items-center">
                        <div class="col-md-4">
                            <img src={fence} class="img-thumbnail" width="500" height="240" alt="Home Fence"></img>
                        </div>
                        <div class="col-md-4">
                            <img src={aerial} class="img-thumbnail" width="500" height="240" alt="Aerial Homes"></img>
                        </div>
                        <div class="col-md-4">
                            <img src={construction} class="img-thumbnail" width="500" height="240" alt="Home under construction"></img>
                        </div>
                    </div>
                    <hr id="insurance_type_line"></hr>
                    {/*Categories*/}
                    <div class="row align-items-center">
                        <div class="col-md-2">
                            
                        </div>
                        <div class="col-md-8">
                            {/*Homeowners */}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#homeowners_homes" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Homeowners</h3>

                                    <div id="homeowners_homes" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                            Home. It's your safe haven. It's where you raise your family and create memories that 
                                            last a lifetime. Whether you're buying insurance for your first home or the high-value 
                                            home of your dreams, we can help you protect this prized possession with homeowners 
                                            insurance that fits your priorities and your budget.
                                        </p>
                                    </div>
                                </div>
                            </a>

                            {/*Tenants/Renters */}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#renters_homes" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Tenants/Renters</h3>

                                    <div id="renters_homes" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                            When you rent or lease, your landlord’s insurance usually only protects the building 
                                            itself — not your personal belongings. We’ll design coverage to protect your personal 
                                            property and your lifestyle while renting, so you can rest easy.
                                        </p>
                                    </div>
                                </div>
                            </a>

                            {/*Condo*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#condo_homes" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Condo</h3>

                                    <div id="condo_homes" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                            There’s a condo community for every taste — whether you prefer a downtown high-rise, 
                                            a loft-style condo conversion or a small complex in a serene setting. Condos often 
                                            feature amenities like swimming pools or security patrols. And there’s a good chance 
                                            your homeowners association takes care of gardening and exterior maintenance. 
                                            It all adds up to a lifestyle you enjoy.
                                            <br></br><br></br>
                                            But your condo is more than a place you call home. It’s also a major investment 
                                            filled with possessions you want to protect.
                                        </p>
                                    </div>
                                </div>
                            </a>

                            {/*Vacant/Unoccupied*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#vacant_homes" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Vacant/Unoccupied</h3>

                                    <div id="vacant_homes" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                            Whether you’re waiting for a home to sell, looking for the right tenant or doing 
                                            renovations, owning a vacant property comes with special risks. The Heberling 
                                            Agency will find insurance options and features that can provide coverage for 
                                            your vacant property.
                                        </p>
                                    </div>
                                </div>
                            </a>

                            {/*Mobile/Manufactured*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#mobile_homes" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Mobile/Manufactured</h3>

                                    <div id="mobile_homes" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                        Why did you choose your mobile or manufactured home? Maybe you wanted the convenience 
                                        and amenities of a well-tended mobile home park. Or perhaps the custom potential of a 
                                        manufactured home was an ideal option for your semi-rural acreage.
                                        <br></br><br></br>
                                        Your home represents a significant financial investment — protecting it takes careful 
                                        research, and a Heberling Agency agent can help as you choose the coverage you want.
                                        </p>
                                    </div>
                                </div>
                            </a>

                            {/*Specialty Homes*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#specialty_homes" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Specialty Homes</h3>

                                    <div id="specialty_homes" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                            Maybe you have a cabin in the mountains, a lake house or a beach cottage. Whether used as a 
                                            weekend getaway or a seasonal retreat, it’s a special place you want to protect. 
                                            A Heberling Agency agent can talk to you about a variety of coverage options.
                                        </p>
                                    </div>
                                </div>
                            </a>

                            {/*Under Construction*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#construction_homes" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Under Construction</h3>

                                    <div id="construction_homes" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                            If you plan on moving into a home that doesn’t yet exist, there’s more to it than finding the 
                                            right contractor. You’ll also want to insure a home being constructed. That’s because a home being 
                                            constructed is also vulnerable to risks like severe weather damage and theft. Let the Heberling 
                                            Agency help you find the right coverage for your dream home
                                        </p>
                                    </div>
                                </div>
                            </a>

                            {/*Older and Lower-Value Homes*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#older_homes" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Older and Lower-Value Homes</h3>

                                    <div id="older_homes" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                            Your home's market value might be lower than it would cost to rebuild it, if disaster were to 
                                            strike. Many insurance companies hesitate to cover older or lower-valued properties, but we have 
                                            some companies that have created specialty policies specifically for these types of homes, and 
                                            are ready to help cover your property where others won’t
                                        </p>
                                    </div>
                                </div>
                            </a>
                 
                            
                        </div>
                        <div class="col-md-2">
                            
                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default HomeInsurance;