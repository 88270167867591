import React, { Component } from 'react';

import dog from '../images/dog.jpg';

class UmbrellaPolicies  extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">
                    {/*Title*/}
                    <div class="row align-items-center">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <h1 id="white_background_text">Umbrella Policies</h1>
                            <hr id="standard_line"></hr>
                            <p class="insurance_type_text" id="homepage_icon_text">
                                This liability coverage can go above and beyond your car and home insurance policies to help protect 
                                you from unexpected events.
                                <br></br><br></br>
                                Umbrella insurance can give you up to $5 million in coverage on top of your auto or homeowners policy 
                                limits. If you injure someone or damage their property, you might not have enough coverage. An 
                                umbrella policy could save you from paying out of your own pocket.
                            </p>
                            
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                    <hr></hr>

                    {/*Images*/}
                    <div id="insurance_images" class="row align-items-center">
                        <div class="col-md-12">
                            <img src={dog} class="img-thumbnail" width="800" height="334" alt="Aerial Homes"></img>
                        </div>
                    </div>
                    <hr id="insurance_type_line"></hr>

                    {/*Categories*/}
                    <div class="row align-items-center">
                        <div class="col-md-2">
                            
                        </div>
                        <div class="col-md-8">
                            {/*Situations*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#situation_umbrella" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">What situations could umbrella insurance cover?</h3>

                                    <div id="situation_umbrella" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                            <ul>
                                                <li>Injuries to other people if you're at fault</li>
                                                <li>Damage to other people's property</li>
                                                <li>Injuries or damage caused by your renters</li>
                                                <li>Certain lawsuits, like slander and defamation</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </a>

                            {/*Buy*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#buy_umbrella" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Why buy umbrella insurance?</h3>

                                    <div id="buy_umbrella" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                            <ul>
                                                <li>Without an umbrella policy, you could lose your home and savings due to a major claim.</li>
                                                <li>Liability claims are often expensive. You might have to work for years to pay off a claim.</li>
                                                <li>Lawsuits can happen to anyone. You can be sued even if you're not wealthy or at-fault.</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </a>

                            {/*Different*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#different_umbrella" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">How is an umbrella insurance policy different from raising policy limits?</h3>

                                    <div id="different_umbrella" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                            <ul>
                                                <li>Can add $5 million in coverage to several policies—and may cost less than raising the limits on other policies individually.</li>
                                                <li>May cover situations that your other policies may not, such as slander, or libel lawsuits.</li>
                                                <li>Can cover the cost of your legal defense in case of a liability lawsuit.</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </a>

                            {/*Covered*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#covered_umbrella" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Who is covered by your umbrella insurance policy? </h3>

                                    <div id="covered_umbrella" class="collapse">
                                        <hr></hr>
                                        <p class="insurance_type_text" id="homepage_icon_text">
                                            <ul>
                                                <li>You and your spouse if a resident of your household</li>
                                                <li>Relatives residing in your household</li>
                                                <li>Household resident under age 21 in the care and custody of you or your spouse</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </a>
                                                        
                        </div>
                        <div class="col-md-2">
                            
                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default UmbrellaPolicies;