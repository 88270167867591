import React, { Component } from 'react';

import contractor from '../images/contractor.jpg';

class GeneralLiability  extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">
                    {/*Title*/}
                    <div class="row align-items-center">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <h1 id="white_background_text">General Liability</h1>
                            <hr id="standard_line"></hr>
                            <p class="insurance_type_text" id="homepage_icon_text">
                                General Liability (GL) insurance helps protect businesses from claims that happen as a 
                                result of normal business operations. GL insurance generally provides coverage for, among 
                                other things, third-party bodily injuries, medical payments, and advertising injuries.
                                <br></br><br></br>
                                This liability insurance provides coverage that helps protect your business from 
                                claims that come from your normal business operations, such as:

                                <ul>
                                    <li>Property damage</li>
                                    <li>Physical injury</li>
                                    <li>Defense costs</li>
                                    <li>Personal and advertising injury</li>
                                </ul>

                                
                                Whether you're a contractor or a small business owner, The Heberling Agency can help you get 
                                the coverage your business needs.
                            </p>
                            
                        </div>
                        <div class="col-md-3"></div>
                    </div>

                    <hr></hr>
                    

                    {/*Images*/}
                    <div id="insurance_images" class="row align-items-center">
                        
                        <div class="col-md-12">
                            <img src={contractor} class="img-thumbnail" width="267" height="467" alt="Contractor"></img>
                        </div>
                      
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default GeneralLiability;