import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class Landing extends Component {
    constructor(props){
        super(props);

        this.state = {
            height:0
        }

        this.handeClick = this.handeClick.bind(this);
    }

    componentDidMount(){
        const height = this.refs.landingPage.clientHeight;
        this.setState({height});
    }

    handeClick(){
        window.scroll({top:this.state.height , left:0, behavior: "smooth"});
    }

    
    render() {
        return (
            <div>
                <div ref="landingPage" id="landingPage">
                    <div id="landingPage_text">
                        <h1 id="landingPage_header">HEBERLING AGENCY INC.</h1>
                        <h2 id="landingPage_subheader">Insurance</h2>
                        <h3 id="landingPage_childheader">Since 1933</h3>
                        <br></br><br></br>

                        <Link id="landingPage_button" class="btn btn-outline-danger" to="/Contact" onClick={this.handeClick}>
                            Get Free Quote
                        </Link>

                       
                        <a id="landingPage_button" href="tel:(570)421-5930" class="btn btn-outline-warning">Call Now:(570) 421-5930</a>
                    
                        <br></br><br></br>
                        
                        <AnchorLink href="#navbar_text" offset={() => 1}><i id="landingPage_icons" class="fa fa-angle-double-down"></i></AnchorLink>
                    </div>
                </div>
            </div>

        );
    }  
}

export default Landing;