import React, { Component } from 'react';

import handswheel from '../images/handswheel.jpg';
import man_suv from '../images/man_suv.jpg';
import benz_wheel from '../images/benz_wheel.jpg';
import vintage_car from '../images/vintage_car.jpg';

class AutoInsurance  extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">
                    {/*Title*/}
                    <div class="row align-items-center">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <h1 id="white_background_text">Auto Insurance</h1>
                            <hr id="standard_line"></hr>
                            <p class="insurance_type_text" id="homepage_icon_text">
                                It’s quick and easy to get a car insurance quote today. Whatever you drive, wherever you go, 
                                feel confident that you, your loved ones and your pockets are properly protected. 
                                Let us worry about the risks of the road, while you enjoy the freedom of the ride.
                            </p>
                            
                        </div>
                        <div class="col-md-3"></div>
                    </div>

                    <hr></hr>
                    

                    {/*Images*/}
                    <div id="insurance_images" class="row align-items-center">
                        <div class="col-md-12">
                            <img src={handswheel} class="img-thumbnail" width="800" height="320" alt="Hands on Wheel"></img>
                        </div>
                    </div>
                    <hr id="insurance_type_line"></hr>
                    {/*Categories*/}
                    <div class="row align-items-center">
                        <div class="col-md-2">
                            
                        </div>
                        <div class="col-md-8">
                            {/*Personal Auto*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#personal_auto" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Personal Auto</h3>

                                    <div id="personal_auto" class="collapse">
                                        <hr></hr>
                                        <div class="row align-items-center">
                                            <div class="col-md-6">
                                                <p class="insurance_type_text" id="homepage_icon_text">
                                                    Home. It's your safe haven. It's where you raise your family and create memories that 
                                                    last a lifetime. Whether you're buying insurance for your first home or the high-value 
                                                    home of your dreams, we can help you protect this prized possession with homeowners 
                                                    insurance that fits your priorities and your budget.
                                                </p>
                                            </div>
                                            <div class="col-md-6">
                                                <img src={man_suv} class="img-thumbnail" width="320" height="214" alt="Man with SUV"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>

                            {/*Commercial Auto*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#commercial_auto" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Commercial Auto</h3>

                                    <div id="commercial_auto" class="collapse">
                                        <hr></hr>
                                        <div class="row align-items-center">
                                            <div class="col-md-6">
                                                <p class="insurance_type_text" id="homepage_icon_text">
                                                    If you use vehicles for your business, get a free commercial auto insurance quote from The 
                                                    Heberling Agency. We have been working with businesses like yours for over 70 years. We work 
                                                    hard to provide your business with the coverage you need at a great price. So whether you 
                                                    have a small, growing, or large business, The Heberling Agency will work with you to 
                                                    create a policy that works best for your business.
                                                </p>
                                            </div>
                                            <div class="col-md-6">
                                                <img src={benz_wheel} class="img-thumbnail" width="320" height="213" alt="Man with SUV"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>

                            {/*Classic, Antique, Collectible and Custom Cars*/}
                            <a id="insurance_type_link" data-toggle="collapse" data-target="#classic_auto" target="_blank" href="">
                                <div class="shadow-lg p-4 mb-4 bg-white">
                                    <h3 id="white_background_text">Classic, Antique, Collectible and Custom Cars</h3>

                                    <div id="classic_auto" class="collapse">
                                        <hr></hr>
                                        <div class="row align-items-center">
                                            <div class="col-md-6">
                                                <p class="insurance_type_text" id="homepage_icon_text">
                                                    If you're a proud owner of a vintage, antique, or collectible car, you need classic car insurance 
                                                    to protect your beloved investment. Whether it's a prime spot in your garage or an extra dab of 
                                                    polish on the wheels, your collector car deserves extra TLC.
                                                    
                                                    The Heberling Agency knows that your collector car is more than just a car. That's why a classic 
                                                    car insurance policy is so much more than just a standard auto policy. Traditional insurance 
                                                    policies may not consider the unique issues that come up when a vehicle represents a significant 
                                                    financial investment, like when replacement parts are nearly impossible to find.
                                                </p>
                                            </div>
                                            <div class="col-md-6">
                                                <img src={vintage_car} class="img-thumbnail" width="320" height="215" alt="Man with SUV"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-md-2">
                            
                        </div>
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default AutoInsurance;