import React, { Component } from 'react';

import jewelry from '../images/jewelry.jpg';
import camera from '../images/camera.jpg';

class PersonalArticles  extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">
                    {/*Title*/}
                    <div class="row align-items-center">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <h1 id="white_background_text">Personal Articles</h1>
                            <hr id="standard_line"></hr>
                            <p class="insurance_type_text" id="homepage_icon_text">
                                Does your homeowners insurance policy cover jewelry and valuable items? Homeowners policies may 
                                provide limited coverage amounts for jewelry or valuable items, based on the type of item and 
                                cause of loss. A personal article policy may provide the protection you need for your valuable 
                                possessions in the event of covered loss from, for example, theft or fire.

                                <br></br><br></br>
                                Consider Valuable Items Coverage for:
                            </p>
                            <p id="insurance_type_bullet">
                                <div class="row ">
                                    <div class="col-md-6">
                                        <ul>
                                            <li>Jewelry and engagement rings</li>
                                            <li>Silverware, china and crystal</li>
                                            <li>Antiques</li>
                                            <li>Fine art</li>
                                            <li>Stamp and coin collections</li>
                                            <li>Bicycles</li>
                                            <li>Trophies</li>
                                            <li>Sound equipment (recorders)</li>
                                            <li>Sports equipment (archery, camping, fishing, skiing)</li>
                                            <li>Wedding gifts</li>
                                        </ul>
                                    </div>
                                    <div class="col-md-6">
                                        <ul>
                                            <li>Cameras</li>
                                            <li>Computers</li>
                                            <li>Musical instruments</li>
                                            <li>Golf Equipment</li>
                                            <li>Furs</li>
                                            <li>Guns</li>
                                            <li>Pedigreed dogs</li>
                                            <li>Garden tractors</li>
                                            <li>Political campaign collections</li>
                                            <li>Watches</li>
                                        </ul>
                                    </div>
                                </div>

                            </p>
                            
                        </div>
                        <div class="col-md-3"></div>
                    </div>

                    <hr></hr>
                    

                    {/*Images*/}
                    <div id="insurance_images" class="row align-items-center">
                        
                        <div class="col-md-6">
                            <img src={jewelry} class="img-thumbnail" width="500" height="333" alt="Harley Davidson"></img>
                        </div>
                        <div class="col-md-6">
                            <img src={camera} class="img-thumbnail" width="500" height="333" alt="Harley Davidson"></img>
                        </div>
                      
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default PersonalArticles;