import React, { Component } from 'react';

var cur_year = new Date().getFullYear();
var footer_text = "HEBERLING AGENCY INC. \u00A9 1933 - " + cur_year;

class Footer extends Component {
  render() {
    return (
        <footer>
          <div class="container">
            <br></br>
            <div class="row align-items-center">
                <div class="col-2"></div>
                <div class="col-8 text-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/HeberlingAgency/">
                    <i id="footer_icon" className="fa fa-facebook fa-2x fa-fw"></i>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/heberlingagency/">
                    <i id="footer_icon" className="fa fa-instagram fa-2x fa-fw"></i>
                  </a>
  
                  <br></br>
                  <p className="footer_name">
                    {footer_text}
                    <small>
                      <br></br>
                      710 Phillips Street &#8226; Stroudsburg &#8226; PA &#8226; 18360 
                      <br></br>
                      (570) 421-5930
                    </small>
                  </p>
                </div>
                <div class="col-2"></div>
            </div>
          </div>
        </footer>
    );
  }
}

export default Footer;