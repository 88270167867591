import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';
import * as emailjs from 'emailjs-com';
import {withRouter} from 'react-router-dom';

import logo from '../images/heberling_logo.jpg';

class Contact extends Component {
    constructor(props){
        super(props);
        this.state = {
            contact_name: '',
            contact_email: '',
            contact_phone: '',
            contact_message: '',
            home_insurance: '',
            auto_insurance: '',
            motor_insurance: '',
            life_insurance: '',
            business_insurance: '',
            general_insurance: '',
            umbrella_insurance: '',
            personal_insurance: '',
            recreational_insurance: '',
            other_insurance: '',
            isHuman: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.recaptchaVerified = this.recaptchaVerified.bind(this);
    }

    handleChange(event){
        const target = event.target;
        var value = target.value;
        const name = target.name;
        const type = target.type;
        const checked = target.checked;

        if (type == "checkbox"){
            if(!checked){
                value = ""
            }
        }

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event){
        if(this.state.isHuman){
            var self = this;

            //Disable button after first click to prevent multiple messages
            this.refs.btn.setAttribute("disabled", "disabled");

            //Assign Email Template Variables
            var templateParams = {
                from_name: this.state.contact_name,
                from_email: this.state.contact_email,
                from_phone: this.state.contact_phone,
                from_insurance: this.state.home_insurance + this.state.auto_insurance + this.state.motor_insurance +
                    this.state.life_insurance + this.state.business_insurance + this.state.general_insurance +
                    this.state.umbrella_insurance + this.state.personal_insurance + 
                    this.state.recreational_insurance + this.state.other_insurance,
                from_message: this.state.contact_message
            };
            
            //Sending Email
            emailjs.send('heberlingagencyinc_gmail_com','template_5SfbSuCj', templateParams, 'user_fdlZEfg9MzWpnJ9HrbzFB').then(function(response) {
                self.props.history.push('/Thanks');
                console.log('SUCCESS!', response.status, response.text);
            }, function(err) {
                self.props.history.push('/Error');
                console.log('FAILED...', err);
            });

            this.props.history.push('/Loading');
            event.preventDefault();
        }else{
            alert("Please verify that you are a human!");
            event.preventDefault();
        }
    }

    recaptchaLoaded(){
        console.log("Capcha successfully laoded");
    }

    recaptchaVerified(response){
        if(response){
            this.setState({
                isHuman: true
            })
        }
    }

    render() {
        return (
            <div className="wrap container-fluid">
                <div id="white_banner" class="jumbotron text-center">
                    <div class="row align-items-center">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <h1 id="white_background_text">Contact</h1>
                            <hr id="standard_line"></hr>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                </div>

                
                <div class="row align-items-center">
                    <div class="col-md-4"></div>
                    <div class="col-md-4 contact_form_div">
                        <form onSubmit={this.handleSubmit} class="contact_form">
                            <div id="contact_info"><h5 id="white_background_text">Free Quote</h5></div>
                            <div class="form-group">
                                <label for="contact_name">Name:</label>
                                <input type="text" value={this.state.contact_name} onChange={this.handleChange} class="form-control" id="contact_name" tabindex="1" placeholder="Your Name *" name="contact_name" required/>
                            </div>
                            <div class="form-group">
                                <label for="contact_email">Email:</label>
                                <input type="email" value={this.state.contact_email} onChange={this.handleChange} class="form-control" id="contact_email" tabindex="2" placeholder="Your Email *" name="contact_email" required/>
                            </div>
                            <div class="form-group">
                                <label for="contact_phone">Phone:</label>
                                <input type="text" value={this.state.contact_phone} onChange={this.handleChange} class="form-control" id="contact_phone" tabindex="3" placeholder="Your Phone" name="contact_phone"/>
                            </div>
                            <div class="form-group">
                                <label for="contact_insurance">Insurance:</label><br></br>
                                <small>
                                    <input type="checkbox" value="(Home)" onChange={this.handleChange} class="contact_checkbox" id="home_insurance" tabindex="3" name="home_insurance"/>Home
                                    <input type="checkbox" value="(Auto)" onChange={this.handleChange} class="contact_checkbox" id="auto_insurance" tabindex="3" name="auto_insurance"/>Auto
                                    <input type="checkbox" value="(Motorcycle)" onChange={this.handleChange} class="contact_checkbox" id="motor_insurance" tabindex="3" name="motor_insurance"/>Motorcycle<br></br>
                                    <input type="checkbox" value="(Life)" onChange={this.handleChange} class="contact_checkbox" id="life_insurance" tabindex="3" name="life_insurance"/>Life
                                    <input type="checkbox" value="(Business Owners)" onChange={this.handleChange} class="contact_checkbox" id="business_insurance" tabindex="3" name="business_insurance"/>Business Owners
                                    <input type="checkbox" value="(General Liability)" onChange={this.handleChange} class="contact_checkbox" id="general_insurance" tabindex="3" name="general_insurance"/>General Liability<br></br>
                                    <input type="checkbox" value="(Umbrella Policies)" onChange={this.handleChange} class="contact_checkbox" id="umbrella_insurance" tabindex="3" name="umbrella_insurance"/>Umbrella Policies
                                    <input type="checkbox" value="(Personal Articles)" onChange={this.handleChange} class="contact_checkbox" id="personal_insurance" tabindex="3" name="personal_insurance"/>Personal Articles<br></br>
                                    <input type="checkbox" value="(Recreational Vehicles)" onChange={this.handleChange} class="contact_checkbox" id="recreational_insurance" tabindex="3" name="recreational_insurance"/>Recreational Vehicles
                                    <input type="checkbox" value="(Other)" onChange={this.handleChange} class="contact_checkbox" id="other_insurance" tabindex="3" name="other_insurance"/>Other
                                </small>
                                
                            </div>
                            <div class="form-group">
                                <label for="contact_message">Message:</label>
                                <textarea type="text" value={this.state.contact_message} onChange={this.handleChange} class="form-control" id="contact_message" tabindex="4" placeholder="Your Message *" name="contact_message" required/>
                            </div>
                            
                            
                            <Recaptcha
                                sitekey="6LfyJr8UAAAAAB9N3wi3ePRB6dWPrGAUR_o1cZlE"
                                render="explicit"
                                onloadCallback={this.recaptchaLoaded}
                                verifyCallback={this.recaptchaVerified}
                            />
                            <br></br>
                            <button ref="btn" type="submit" class="btn btn-primary">Send</button>
                        </form>
                    </div>
                    <div class="col-md-4"></div>
                </div>
            
                <br></br><br></br>
                <hr class="padding_line"></hr>

                <div id="contact_info" class="row align-items-center">
                    <div class="col-md-1"></div>
                    <div class="col-md-5">
                        {/*<i id="homepage_icons" class="fa fa-map"></i>*/}
                        <img src={logo} width="230" height="79" alt="Heberling Insurance"></img>
                        <h5 id="white_background_text">HEBERLING AGENCY INC.</h5>
                        <h7 id="white_background_text">710 Phillips Street, Stroudsburg, PA 18360</h7>
                        <br></br>
                        <h7 id="white_background_text" ><a href="tel:(570)421-5930">(570) 421-5930</a></h7>
                        <br></br>
                        <h7 id="white_background_text" ><a href="mailto:heberling@verizon.net">heberling@verizon.net</a></h7>

                        


                        <br></br><br></br>
                        <h5 id="white_background_text">Hours of Operation</h5>
                        <h7 id="white_background_text">9:00 AM - 4:00 PM</h7>
                        <br></br>
                        <h7 id="white_background_text">Monday - Friday</h7>
                    </div>
                    <div class="col-md-5">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.5073651524626!2d-75.20029478468598!3d40.99226697930246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c4885254f853af%3A0x9b7fe6842c7e8dd9!2s710%20Phillips%20St%2C%20Stroudsburg%2C%20PA%2018360!5e0!3m2!1sen!2sus!4v1572203139577!5m2!1sen!2sus" width="400" height="400" frameborder="0"  allowfullscreen=""></iframe>
                    </div>
                    <div class="col-md-1"></div>

                </div>
                
            </div>
        );
    }
}

export default withRouter(Contact);

