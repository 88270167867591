import React, { Component } from 'react';
import {
  HashRouter as Router,
  Route,
  Link
} from 'react-router-dom';

//Components
import Landing from './Components/pages/landingPage.js';
import Header from './Components/headerComponent/header.js';
import Homepage from './Components/pages/homePage.js';
import Contact from './Components/pages/contactPage.js';
import Loadingpage from './Components/pages/loadingPage.js';
import Thankspage from './Components/pages/thanksPage.js';
import Errorpage from './Components/pages/errorPage.js';
import AboutUs from './Components/pages/aboutPage.js';
import Team from './Components/pages/teamPage.js';
import HomeInsurance from './Components/pages/homeInsurance.js';
import AutoInsurance from './Components/pages/autoInsurance.js';
import MotorcycleInsurance from './Components/pages/motorcycleInsurance.js';
import LifeInsurance from './Components/pages/lifeInsurance.js';
import BusinessOwners from './Components/pages/businessInsurance.js';
import GeneralLiability from './Components/pages/generalLiability.js';
import UmbrellaPolicies from './Components/pages/umbrellaPolicies.js';
import PersonalArticles from './Components/pages/personalArticles';
import RecreationalVehicles from './Components/pages/recreationalVehicles';
import Footer from './Components/footerComponent/footer.js';


//Assets
import './Assets/css/default.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Landing />
          <Header />
            <Route exact path='/' component={Homepage} />
            <Route exact path='/Contact' component={Contact} />
              <Route exact path='/Loading' component={Loadingpage} />
              <Route exact path='/Thanks' component={Thankspage} />
              <Route exact path='/Error' component={Errorpage} />
            <Route exact path='/AboutUs' component={AboutUs} />
            <Route exact path='/Team' component={Team} />



            <Route exact path='/HomeInsurance' component={HomeInsurance} />
            <Route exact path='/AutoInsurance' component={AutoInsurance} />
            <Route exact path='/MotorcycleInsurance' component={MotorcycleInsurance} />
            <Route exact path='/LifeInsurance' component={LifeInsurance} />
            <Route exact path='/BusinessOwners' component={BusinessOwners} />
            <Route exact path='/GeneralLiability' component={GeneralLiability} />
            <Route exact path='/UmbrellaPolicies' component={UmbrellaPolicies} />
            <Route exact path='/PersonalArticles' component={PersonalArticles} />
            <Route exact path='/RecreationalVehicles' component={RecreationalVehicles} />
          <Footer />
        </div>
      </Router>
    );  
  }
}

export default App;
