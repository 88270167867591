import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import progressive from '../images/progressive.png';
import travelers from '../images/travelers.png';
import foremost from '../images/foremost.jpg';
import capitol from '../images/capitol.jpg';
import aegis from '../images/aegis.png';
import national_general from '../images/national_general.png';
import donegal from '../images/donegal.png';
import american_modern from '../images/american_modern.png';
import safeco from '../images/safeco.jpg';
import condon_skelly from '../images/condon_skelly.png';
import gateway from '../images/gateway.png';
import hull from '../images/hull.png';
import dgk from '../images/dgk.png';
import hartford from '../images/the-hartford-logo.png';


class Homepage extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">
                    <div class="row align-items-center">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <h1 id="white_background_text">Featured Products</h1>
                            <hr id="standard_line"></hr>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                    <br></br><br></br>
                    <div class="row align-items-center">
                        <div class="col-md-4">
                            <div class="zoom">
                                <Link id="homepage_links" to="/HomeInsurance">
                                    <i id="homepage_icons" class="fa fa-home homepage_circle_icon"></i>
                                    <h5 id="homepage_icon_text">Home Insurance</h5>
                                </Link>
                            </div>
                            <div class="zoom">
                                <Link id="homepage_links" to="/LifeInsurance">
                                    <i id="homepage_icons" class="fa fa-heartbeat"></i>
                                    <h5 id="homepage_icon_text">Life Insurance</h5>
                                </Link>
                            </div>
                            <div class="zoom">
                                <Link id="homepage_links" to="/UmbrellaPolicies">
                                    <i id="homepage_icons" class="fa fa-umbrella"></i>
                                    <h5 id="homepage_icon_text">Umbrella Policies</h5>
                                </Link>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="zoom">
                                <Link id="homepage_links" to="/AutoInsurance">
                                    <i id="homepage_icons" class="fa fa-car"></i>
                                    <h5 id="homepage_icon_text">Auto Insurance</h5>
                                </Link>
                            </div>
                            <div class="zoom">
                                <Link id="homepage_links" to="/BusinessOwners">
                                    <i id="homepage_icons" class="fa fa-briefcase"></i>
                                    <h5 id="homepage_icon_text">Business Owners</h5>
                                </Link>
                            </div>
                            <div class="zoom">
                                <Link id="homepage_links" to="/PersonalArticles">
                                    <i id="homepage_icons" class="fa fa-diamond"></i>
                                    <h5 id="homepage_icon_text">Personal Articles</h5>
                                </Link>
                            </div>
                        </div>
                      
                        <div class="col-md-4">
                            <div class="zoom">
                                <Link id="homepage_links" to="/MotorcycleInsurance">
                                    <i id="homepage_icons" class="fa fa-motorcycle"></i>
                                    <h5 id="homepage_icon_text">Motorcycle Insurance</h5>
                                </Link>
                            </div>
                            <div class="zoom">
                                <Link id="homepage_links" to="/GeneralLiability">
                                    <i id="homepage_icons" class="fa fa-wrench"></i>
                                    <h5 id="homepage_icon_text">General Liablility</h5>
                                </Link>
                            </div>
                            <div class="zoom">
                                <Link id="homepage_links" to="/RecreationalVehicles">
                                    <i id="homepage_icons" class="fa fa-bus"></i>
                                    <h5 id="homepage_icon_text">Recreational Vehicles</h5>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>



                {/* TESTIMONIALS */}
                <div id="hompage_testimonial" class="container-fluid text-center">
                    <br></br>
                    <div class="row align-items-center">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <h1 id="white_background_text">Our Customers</h1>
                            <hr id="standard_line"></hr>
                        </div>
                        <div class="col-md-4"></div>
                    </div>


                    <div class="row align-items-center">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <div id="demo" class="carousel slide" data-ride="carousel">
                                {/*<ul class="carousel-indicators">
                                    <li data-target="#demo" data-slide-to="0" class="active"></li>
                                    <li data-target="#demo" data-slide-to="1"></li>
                                    <li data-target="#demo" data-slide-to="2"></li>
                                </ul>*/}
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " This business is a wonderful family run business<br></br>
                                            that is very accomodating and personable!!<br></br>
                                            Would highly recommend! "<br></br><br></br>
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Courtney Kern
                                        </p>
                                    </div>
                                    <div class="carousel-item">                                        
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span> 
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " Great family owned buisness!<br></br>
                                            Very knowledgable and helpful with <br></br>
                                            my auto and renters insurance!<br></br>
                                            Would deff recommend. "

                                        </p>
                                        <p  id="testimonial_quote">
                                            - Ryan Gentile
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " Wish there were more places like these guys still around. <br></br>
                                            Family run for as long as I can remember, always get<br></br> 
                                            talk to people who know and remember me and my situation.<br></br>
                                            Have helped me out with lower quotes many times. "
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Dennis Reynolds
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " The Heberling Agency is great. <br></br>
                                            Great service and is very hospitable. <br></br>
                                            I would recommend going. "<br></br><br></br>
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Sarah Candy
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " I have been with them for many years. They are hands-down the <br></br> 
                                            best insurance company in the Pocono Mountains. They are<br></br>
                                            knowledgeable and their customer service is <br></br>
                                            superior to none. I highly recommend them "
                                        </p>
                                        <p  id="testimonial_quote">
                                            - David J Henon
                                        </p>
                                    </div>






                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " This Agency is great.  <br></br> 
                                            If you live in PA and are not using them, you're wrong. "<br></br>
                                            <br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - John Rey
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " Wonderful personalized service.  <br></br> 
                                            Excellent help finding an appropriate product at reasonable prices. "<br></br>
                                            <br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Michelle Maslow
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " Eliminates the head ache of dealing with these big insurance companies.  <br></br> 
                                            Ask for Emily, she the best person to deal with. "<br></br>
                                            <br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Chase Sando
                                        </p>
                                    </div>

                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " Great company. I was with progressive directly for many years  <br></br> 
                                            and thought i had the lowest rate, but somehow they got it even lower. "<br></br>
                                            <br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Todd Dahlstrom
                                        </p>
                                    </div>

                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " Best Agent in the State. "<br></br> 
                                            <br></br>
                                            <br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Ky Kitz
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " There is something special about sitting down with people like <br></br> 
                                            the ones at Heberling! A great personal experience far outweighing a <br></br>
                                            long distance computer generated one, where you never meet face to face "<br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - msmanor121
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " Awesome family run business! Extremely helpful and willing.  <br></br> 
                                            Definitely recommend. " <br></br>
                                            <br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Kortney Kunkle
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " Very helpful and respectful with everything I needed. " <br></br> 
                                            <br></br>
                                            <br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Ryan Lobb
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " Very friendly " <br></br> 
                                            <br></br>
                                            <br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Tom Serrenti
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " Extremely professional and friendly staff " <br></br> 
                                            <br></br>
                                            <br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Nicholas Detrick
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            " Emily is my agent and she is the best!  <br></br> 
                                            She makes everything so easy to understand and always shops for the best rates"
                                            <br></br>
                                            <br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Karla Malanga
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            "Steve and Emily at Heberling have always been there for me. <br></br>
                                            They have saved me money on my auto homeowners and business policies. <br></br>
                                            If I ever have a question or concern they take care of it right away. <br></br>
                                            They know what they are doing and I recommend them to all"
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Betty Disanto
                                        </p>
                                    </div>
                                    <div class="carousel-item">
                                        <h6>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                            <span class="fa fa-star checked"></span>
                                        </h6>
                                        <p id="homepage_icon_text">
                                            "Heberling Agency is with you for the long haul. They have adapted my coverage  <br></br>as my
                                            life has changed and I have complete trust in their recommendations. <br></br>
                                            They have never steered me wrong. 10/10 recommend."<br></br><br></br>
                                            
                                        </p>
                                        <p  id="testimonial_quote">
                                            - Chris Ward
                                        </p>
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#demo" data-slide="prev">
                                    <span class="carousel-control-prev-icon"></span>
                                </a>
                                <a class="carousel-control-next" href="#demo" data-slide="next">
                                    <span class="carousel-control-next-icon"></span>
                                </a>
                            </div>                           
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                </div>

                {/* PARTNERS */}
                <div id="white_banner" class="jumbotron text-center">
                    <div class="row align-items-center">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <h1 id="white_background_text">Partners</h1>
                            <hr id="standard_line"></hr>
                        </div>
                        <div class="col-md-4"></div>
                    </div>

                    {/* FIRST ROW OF PARTNERS */}
                    <div class="row align-items-center">
                        <div class="col-md-4 homepage_image">
                            <img src={progressive} width="150" height="27" alt="Progressive Insurance"></img>
                        </div>
                        <div class="col-md-4 homepage_image">
                            <img src={travelers} width="150" height="42" alt="Travelers Insurance"></img>
                        </div>
                        <div class="col-md-4 homepage_image">
                            <img src={foremost} width="150" height="51" alt="Foremost Insurance"></img>
                        </div>
                    </div>

                    {/* SECOND ROW OF PARTNERS */}
                    <div class="row align-items-center">
                        <div class="col-md-4 homepage_image">
                            <img src={capitol} width="150" height="68" alt="Capitol Insurance"></img>
                        </div>
                        <div class="col-md-4 homepage_image">
                            <img src={aegis} width="150" height="70" alt="Aegis Insurance"></img>
                        </div>
                        <div class="col-md-4 homepage_image">
                            <img src={national_general} width="150" height="48" alt="National General Insurance"></img>
                        </div>
                    </div>

                    {/* THIRD ROW OF PARTNERS */}
                    <div class="row align-items-center">
                        <div class="col-md-4 homepage_image">
                            <img src={donegal} width="150" height="46" alt="Donegal Insurance"></img>
                        </div>
                        <div class="col-md-4 homepage_image">
                            <img src={american_modern} width="150" height="60" alt="American Modern Insurance"></img>
                        </div>
                        <div class="col-md-4 homepage_image">
                            <img src={safeco} width="150" height="83" alt="Safeco Insurance"></img>
                        </div>
                    </div>

                    {/* FOURTH ROW OF PARTNERS */}
                    <div class="row align-items-center">
                        <div class="col-md-4 homepage_image">
                            <img src={gateway} width="150" height="53" alt="Gateway Insurance"></img>
                        </div>
                        <div class="col-md-4 homepage_image">
                            <img src={condon_skelly} width="150" height="76" alt="Condon Skelly Insurance"></img>
                        </div>
                        <div class="col-md-4 homepage_image">
                            <img src={hull} width="150" height="53" alt="Hull Insurance"></img>
                        </div>
                    </div>

                    {/* FIFTH ROW OF PARTNERS */}
                    <div class="row align-items-center">
                        <div class="col-md-4 homepage_image">
                        <img src={hartford} width="150" height="63" alt="Hartford Insurance"></img>
                        </div>
                        <div class="col-md-4 homepage_image">
                            <img src={dgk} width="150" height="29" alt="DGK Insurance"></img>
                        </div>
                        <div class="col-md-4 homepage_image">
                            
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Homepage;