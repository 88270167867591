import React, { Component } from 'react';

import harley from '../images/harley.jpg';

class MotorcycleInsurance  extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="white_banner" class="jumbotron text-center">
                    {/*Title*/}
                    <div class="row align-items-center">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <h1 id="white_background_text">Motorcycle Insurance</h1>
                            <hr id="standard_line"></hr>
                            <p class="insurance_type_text" id="homepage_icon_text">
                                Whether you're enjoying a long summer time ride, or have your bike stored away for the winter, 
                                a motorcycle insurance policy can help protect you and your motorcycle. The Heberling Agency 
                                offers a comprehensive array of options to help provide peace of mind for every ride.
                            </p>
                            
                        </div>
                        <div class="col-md-3"></div>
                    </div>

                    <hr></hr>
                    

                    {/*Images*/}
                    <div id="insurance_images" class="row align-items-center">
                        
                        <div class="col-md-12">
                            <img src={harley} class="img-thumbnail" width="500" height="334" alt="Harley Davidson"></img>
                        </div>
                      
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default MotorcycleInsurance;